<template>
<div>
    <router-view v-show="render" />
    <loading-screen v-show="!render" />
</div>
</template>

<script>
export default {
  name: 'App',
  components: {
    "loading-screen": () => import("./components/Utils/LoadingScreen.vue"),
  },
  data: () => ({
    render: false,
    trigger_translate: false,
    default_language: null
  }),
  mounted() {
    this.render = false;
    this.getToken();
    // document.onreadystatechange = () => {
    // if (document.readyState == "complete") {
    //     window.doGTranslate('en|' + this.$store.state.language);
    //   console.log('Page completed with image and files!')
    // }
//   }
  if(navigator.language || navigator.userLanguage){
    let lang = navigator.language || navigator.userLanguage;
    if(lang == 'fil'){
      this.default_language = 'tl';
    }
    else{
      this.default_language = ((navigator.language || navigator.userLanguage).substring(0,2)).toLowerCase();
    }
  }
    console.log(this.$store.state.token)
  },
  methods: {
    getToken() {
        this.render = false;
        let urlParams = new URLSearchParams(window.location.search);
      if(this.$route.path == '/terms-of-use/' || this.$route.path == '/privacy-policy/' || this.$route.path == '/cookie-policy/' || this.$route.path == '/disclaimers/' || this.$route.path == '/digital-services-act/'){
        this.render = true;
      }
      else if(this.$route.path == '/application/' + this.$store.state.token + '/payment/' && (urlParams.has('error') || urlParams.has('rerror'))){
        this.$store.dispatch("setRevolutError", urlParams.get('error') || null);
        this.$store.dispatch("setRevolutRerror", urlParams.get('rerror') || null);
        this.$router.push('/application/' + this.$store.state.token + '/payment/')
        this.$router.go()
      }
      else if (this.$route.params.token && this.validateToken(this.$route.params.token)) {
        if(this.$route.params.token != this.$store.state.token){
          this.$store.dispatch("setToken", this.$route.params.token);
          this.trigger_translate = true
          // console.log("redirect here 1")
        }
        if(this.$route.path == '/docupass/' + this.$route.params.token + '/'){
          this.goToDocupass(this.$route.params.token);
      }
        else{
          this.loadFormdata();
        }
      }
      else if([...urlParams.keys()].length > 0){
        this.$store.dispatch("setAffId", 'internal');
        this.$store.dispatch("setAffCId", urlParams.has('gclid') ? urlParams.get('gclid') : null);
        const param_data = JSON.stringify(Object.fromEntries(urlParams.entries()));
        console.log(param_data)
        this.$store.dispatch("setAffTxnId", param_data);
        this.$store.dispatch("setFormdata", null);
        this.$store.dispatch("setLanguage", this.default_language);
        let token = this.generate_unique_identifier(2, "numbers") + "-" + this.generate_unique_identifier(2, "chars_upper") + "-" + this.generate_unique_identifier(8, "numbers");
        this.$store.dispatch("setToken", token);
        this.$router.push('/application/' + token + '/');
        this.$router.go()
      }
      else if(this.$store.state.token && this.validateToken(this.$store.state.token)){
          this.loadFormdata();
      }
      else{
        this.$store.dispatch("setFormdata", null);
        this.$store.dispatch("setLanguage", this.default_language);
        let token = this.generate_unique_identifier(2, "numbers") + "-" + this.generate_unique_identifier(2, "chars_upper") + "-" + this.generate_unique_identifier(8, "numbers");
        this.$store.dispatch("setToken", token);
        this.$router.push('/application/' + token + '/');
        this.$router.go()
      }
    },
  loadFormdata(){
    let token = this.$store.state.token;
    this.axiosCall(this.apiUrl2 + "/esta/load/?media_property=usa-esta&token=" + token, "GET")
          .then((res) => {
            if(res.data.data) {
              let formdata = this.setupFormdata(JSON.parse(res.data.data));
              formdata.last_completed_step = res.data.last_completed_step;
              this.$store.dispatch("setFormdata", formdata);
              this.$store.dispatch("setLanguage", res.data.lang == 'zh' ? 'zh-CN' : res.data.lang);
              if(res.data.completed == 1 && res.data.paid == 0 ){
                this.$router.push("/application/" + token + "/payment/", () => {});
              }
              else if(res.data.completed == 1 && res.data.paid == 1 && formdata.additional_info_submitted == 0){
                this.$router.push("/application/" + token + "/additional-info/", () => {});
              }
              else if(res.data.completed == 1 && res.data.paid == 1 && formdata.additional_info_submitted == 1){
                if(this.$route.path != '/docupass/' + this.$route.params.token + '/'){
                  this.$router.push("/docupass/" + token + '/', () => {});
                  this.$router.go()
                }
              }
              else{
                this.$router.push('/application/' + token + '/', () => {});

              }
            // console.log("redirect here 3")
            }
            else{
              this.$store.dispatch("setFormdata", null);
              this.$store.dispatch("setLanguage", this.default_language);
              this.$router.push('/application/' + token + '/', () => {});
              // console.log("redirect here 4")
            }
            if(this.trigger_translate){
                this.$router.go()
            }
            setTimeout(() => {
                this.render = true;
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            // console.log("redirect here 5")

          });
  },
  goToDocupass(token) {
    this.render = false;
    console.log(token)
    this.axiosCall(this.apiUrl2 + "/esta/docupass2/?token=" + token, "GET")
          .then((res) => {
              if(res.data.validation_url){
                // console.log(res.data.validation_url)
                window.open(res.data.validation_url, "_self");
              }
              else{
                this.$router.push("/application/" + token + '/', () => {});
                this.$router.go();
              }
          })
          .catch((error) => {
            console.log(error);
          });
  },
  setupFormdata(data){
    let formdata = {};
    formdata.aff_id = data.aff_id;
    formdata.aff_cid = data.aff_cid;
    formdata.additional_info_submitted = data.additional_info_submitted;
    formdata.applicant = {
        email: data.email,
        first_given_names: this.decodeName(data.first_given_names),
        family_names: this.decodeName(data.family_names),
        previous_names_bool: data.previous_names_bool,
        previous_names: data.previous_names,
        dob_day: data.dob_day,
        dob_month: data.dob_month,
        dob_year: parseInt(data.dob_year),
        birth_country: data.birth_country,
        birth_city: data.birth_city,
    }
    formdata.passport = {
        country_of_citizenship: data.country_of_citizenship,
        issuing_country: data.issuing_country,
        passport_number: data.passport_number,
        passport_number_confirm: data.passport_number,
        passport_issuance_day: data.passport_issuance_day,
        passport_issuance_month: data.passport_issuance_month,
        passport_issuance_year: data.passport_issuance_year ? parseInt(data.passport_issuance_year) : null,
        passport_expiration_day: data.passport_expiration_day,
        passport_expiration_month: data.passport_expiration_month,
        passport_expiration_year: data.passport_expiration_year ? parseInt(data.passport_expiration_year) : null,
        national_identification_number: data.national_identification_number,
        personal_identification_number: data.personal_identification_number,
        gender: data.gender,
        has_other_issuing_country_bool: data.has_other_issuing_country_bool,
        has_other_issuing_country: data.has_other_issuing_country,
        has_other_citizenship_country_bool: data.has_other_citizenship_country_bool,
        has_other_citizenship_country: data.has_other_citizenship_country,
        has_other_citizenship_country_before_bool: data.has_other_citizenship_country_before_bool,
        has_other_citizenship_country_before: data.has_other_citizenship_country_before,
        did_not_acquire_citizenship_bool: data.did_not_acquire_citizenship_bool || null,
        renounced_citizenship_bool: data.renounced_citizenship_bool || null,
        have_not_lived_birth_country_in_5_years_bool: data.have_not_lived_birth_country_in_5_years_bool || null,
        have_not_held_passport_in_past_5_years_bool: data.have_not_held_passport_in_past_5_years_bool || null,
        other_bool: data.other_bool || null,
        other_text: data.other_text || null,
        has_ge_passid_bool: data.has_ge_passid_bool,
        ge_passid: data.ge_passid,
    }
    formdata.personal = {
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        apartment_number: data.apartment_number,
        city: data.city,
        state: data.state,
        country: data.country,
        telephone_type: data.telephone_type ? data.telephone_type.charAt(0).toUpperCase() + data.telephone_type.slice(1) : null,
        phone_number: data.phone_number,
        mother_family_names: this.decodeName(data.mother_family_names),
        mother_first_given_names: this.decodeName(data.mother_family_names),
        father_family_names: this.decodeName(data.father_family_names),
        father_first_given_names: this.decodeName(data.father_first_given_names)
    }
    formdata.social_media = {
        social_media_presence_bool: data.social_media_presence_bool,
        facebook_page_id: data.facebook_page_id,
        linkedin_profile_link: data.linkedin_profile_link,
        twitter_user_id: data.twitter_user_id,
        instagram_username: data.instagram_username,
        platform: data.platform,
        social_media_identifier: data.social_media_identifier,
    }
    formdata.employment = {
        current_previous_employer_bool: data.current_previous_employer_bool,
        job_title: data.job_title,
        employer_name: data.employer_name,
        employer_address_line_1: data.employer_address_line_1,
        employer_address_line_2: data.employer_address_line_2,
        employer_city: data.employer_city,
        employer_state: data.employer_state,
        employer_country: data.employer_country,
        employer_phone_number: data.employer_phone_number,
    }
    formdata.travel = {
        transit_bool: typeof data.transit_bool == "boolean" ? data.transit_bool : null,
        us_contact_unknown_bool: typeof data.us_contact_unknown_bool == "boolean" ? data.us_contact_unknown_bool : null,
        us_contact_name: data.us_contact_name || null,
        us_contact_address_line_1: data.us_contact_address_line_1 || null,
        us_contact_address_line_2: data.us_contact_address_line_2 || null,
        us_contact_apartment_number: data.us_contact_apartment_number || null,
        us_contact_city: data.us_contact_city || null,
        us_contact_state: data.us_contact_state || null,
        us_contact_phone_number: data.us_contact_phone_number || null,
        us_address_same_as_us_contact_bool: typeof data.us_address_same_as_us_contact_bool == "boolean" ? data.us_address_same_as_us_contact_bool : null,
        us_address_line_1: data.us_address_line_1 || null,
        us_address_line_2: data.us_address_line_2 || null,
        us_apartment_number: data.us_apartment_number || null,
        us_city: data.us_city || null,
        us_state: data.us_state || null,
        emergency_contact_family_names: this.decodeName(data.emergency_contact_family_names),
        emergency_contact_first_given_names: this.decodeName(data.emergency_contact_first_given_names),
        emergency_contact_email_address: data.emergency_contact_email_address,
        emergency_contact_phone_number: data.emergency_contact_phone_number,
    }
    formdata.eligibility = {
        mental_disorder_bool: data.mental_disorder_bool || false,
        been_arrested_bool: data.been_arrested_bool || false,
        drugs_bool: data.drugs_bool || false,
        terrorist_bool: data.terrorist_bool || false,
        obtain_visa_bool: data.obtain_visa_bool || false,
        usa_employment_bool: data.usa_employment_bool || false,
        rejected_visa_bool: data.rejected_visa_bool || false,
        stay_longer_bool: data.stay_longer_bool || false,
        been_in_forbidden_countries_bool: data.been_in_forbidden_countries_bool || false,
        been_in_cuba_bool: data.been_in_cuba_bool || false,
    }
    formdata.certification = {
        agree_with_applicant_declaration_bool: data.agree_with_applicant_declaration_bool,
        agree_with_applicant_declaration_timestamp: data.agree_with_applicant_declaration_timestamp,
        agree_with_applicant_declaration_ip: data.agree_with_applicant_declaration_ip,
        agree_with_applicant_declaration_uag: data.agree_with_applicant_declaration_uag,
        agree_with_third_party_authorization_bool: data.agree_with_third_party_authorization_bool,
        agree_with_third_party_authorization_timestamp: data.agree_with_third_party_authorization_timestamp,
        agree_with_third_party_authorization_ip: data.agree_with_third_party_authorization_ip,
        agree_with_third_party_authorization_uag: data.agree_with_third_party_authorization_uag,
        signed_application_bool: data.signed_application_bool,
        signed_application_as: this.decodeName(data.signed_application_as),
        signed_application_timestamp: data.signed_application_timestamp,
        signed_application_ip: data.signed_application_ip,
        signed_application_uag: data.signed_application_uag,
    }
    console.log(formdata)
    return formdata;
  },
  },
};
</script>